import React from 'react';
import Header from '../../components/Header/Header';
import createDOMPurify from 'dompurify';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import FAQMovioEN from './translations/en/FAQ.html';

const sanitize = (html) => {
    if (!window) return '';
    return createDOMPurify(window).sanitize(html);
};

const getFAQTranslated = (lang) => {
    switch (lang) {
        default:
            return FAQMovioEN;
    }
};

const FAQ = () => {
    const { lang, product } = useGetPageConfig();
    let faqTemplate = getFAQTranslated(lang);
    if (product === 'fun-box') {
        faqTemplate = faqTemplate.replaceAll('Movio', 'Funbox');
        faqTemplate = faqTemplate.replaceAll('help@moviotv.online', 'help@fun-box.net');
        faqTemplate = faqTemplate.replaceAll('14.99', '24,99');
    } else if (product === 'entertainu') {
        faqTemplate = faqTemplate.replaceAll('Movio', 'EntertainU');
        faqTemplate = faqTemplate.replaceAll('help@moviotv.online', 'help@entertainu.tv ');
        faqTemplate = faqTemplate.replaceAll('14.99', '24,99');
    }
    return (
        <>
            <Header forceHideHamburger />
            <div className="static-pages">
                <div dangerouslySetInnerHTML={{ __html: sanitize(faqTemplate) }} />
            </div>
        </>
    );
};

export default FAQ;
